import React from "react"

const OfficerChangeCost = () => {
  const title = `役員変更の費用例`
  const content = `上記のケースにおいて弊所で役員変更登記をご依頼いただいた場合の費用例を下記にご紹介しましたのでご参照ください。
ただし、想定事例限定のものとなりますので、個別の御見積については、お気軽にお問い合わせ下さい。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="establish__cost__exam">
          <h3>想定条件</h3>
          <ul>
            <li>会社の資本金： １億円未満</li>
            <li>株主総会議事録・取締役会議事録等等付属書類作成</li>
            <li>登記完了後の登記事項証明書　１通</li>
            <li>登記完了後の登記事項証明書　１通</li>
            <li>事務所来所等出張なしの手続</li>
          </ul>
          <h4>以上の条件による役員変更登記費用例</h4>
          <p>４７,７５０円</p>
          <p>（報酬・実費・消費税（10%）込）</p>
        </div>
      </div>
    </div>
  )
}

export default OfficerChangeCost
