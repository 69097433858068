import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"

const BlogLink = ({ link, linkName }) => {
  return (
    <div className="blog-link">
      <Link to={`/category/${link}/`}>
        <FontAwesomeIcon icon={faExternalLinkAlt} />
        <span>{linkName}関連の記事</span>
      </Link>
    </div>
  )
}

export default BlogLink
