import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import OfficerChangeCost from "../components/officerChangeDetail/officerChangeCost"
import OfficerSyorui from "../components/officerChangeDetail/officerSyorui"
import SEO from "../components/seo"

const Officerchange = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      officerchange: file(relativePath: { eq: "officer.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "会社の役員変更に必要な手続きの流れや、費用例などについて、大阪の司法書士事務所、司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="会社役員変更登記 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.officerchange.childImageSharp.original.src}
        pageimgw={data.officerchange.childImageSharp.original.width}
        pageimgh={data.officerchange.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"officerchange"}
          fluid={data.officerchange.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"役員変更"}
        />
        <section className="officerchange">
          <div className="officerchange__container">
            <h1 className="officerchange__title">役員変更</h1>
            <OfficerSyorui />
            <OfficerChangeCost />
            <BlogLink link={"officerchange"} linkName={"役員変更"} />
          </div>
        </section>
        <BackContact link={"/corp/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Officerchange
