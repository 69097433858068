import React from "react"
import Img from "gatsby-image"

const HeroImage = ({ name, fluid, title, subTitle }) => {
  return (
    <section className={`${name}__hero`}>
      <div className={`${name}__hero__container`}>
        <Img fluid={fluid} className={`${name}__hero__img`} alt={subTitle} />
        <div className={`${name}__hero__title__container`}>
          <h1 className={`${name}__hero__title`}>{title}</h1>
          <p className={`${name}__hero__subTitle`}>{subTitle}</p>
        </div>
      </div>
    </section>
  )
}

export default HeroImage
