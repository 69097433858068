import { Link } from "gatsby"
import React from "react"

const BackContact = ({ link, linkName }) => {
  return (
    <div className="link__area">
      <Link to={link}>
        <button className="link__btn">{linkName}</button>
      </Link>
      <Link to={"/contact/"}>
        <button className="link__btn">お問い合わせ</button>
      </Link>
    </div>
  )
}

export default BackContact
