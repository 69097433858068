import React from "react"

const OfficerSyorui = () => {
  const title = `役員変更手続`
  const content = `株式会社の役員（取締役や監査役）には、「任期」があります。役員の任期は、原則として選任されてから約２年後（監査役は約４年）の定時株主総会の終了時までですが、非公開会社（※）では、最長約１０年まで伸長することができます。
会社の任期は、「定款」に定められていますのでご確認ください。

役員の任期が満了すると、その役員は原則として退任となり、株主総会であらたな役員を選任するか、現在の役員を再度選任していただく必要があります。何も手続きをしなかった場合でも自動で継続とはなりませんのでご注意ください。

※非公開会社・・・会社の全ての株式について、株式を譲渡するのに株主総会や取締役会の承認が必要となるなどの制限が付けられている株式会社のことをいいます。

任期の計算は、上記の通り原則としてその役員が選任されてから約２年（非公開会社では約最長１０年）となりますが、次のケースでは、任期の計算が複雑になる場合があります。
・任期途中で役員を辞任した人の後任の役員
・任期途中で役員を増員した場合
・定款変更し役員の任期を変更した

ご不明な点がありましたら、是非ご相談ください。

その他、役員の任期満了以外に解任、死亡、役員の氏名変更、代表取締役の住所変更などがあった場合にも役員変更の登記が必要となります。

以下は、一般的な役員変更登記手続きに必要となる書類の説明です。

取締役会設置会社で、現在の取締役Ａ・Ｂ・Ｃ、代表取締役Ａ、監査役Ｘの全員が定時株主総会で任期満了し、あらたに取締役Ｂ・Ｃ・Ｄ、代表取締役Ｄ、監査役Ｘを選任した場合
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．定時株主総会議事録</dt>
            <dd>
              役員の任期が満了する定時株主総会において、新役員を選任します。
            </dd>

            <dt>２．株主の住所氏名、議決権数等を証する書面（株主リスト）</dt>
            <dd>定時株主総会における株主の構成を証明する書面です。</dd>

            <dt>３．取締役会議事録</dt>
            <dd>
              代表取締役は、取締役としての任期が満了すると、同時に代表取締役としての地位も喪失します。よって、定時株主総会後、取締役会を開催し、選ばれた取締役の中から代表取締役をあらためて選定する必要があります。
              <br />
              また、代表取締役を選定した取締役会議事録には、原則として出席した取締役、監査役の実印を押印する必要があります。
            </dd>

            <dt>４．取締役・監査役・代表取締役の就任承諾書</dt>
            <dd></dd>
            <dt>５．取締役Ｂ・Ｃ・Ｄ、監査役Ｘの印鑑証明書</dt>
            <dd>
              印鑑届出をする代表取締役の印鑑証明書は発行から３ヶ月以内のものである必要が有ます。
            </dd>
            <dt>６．印鑑届書</dt>
            <dd>
              印鑑届書を法務局に提出することにより、新しい代表取締役の名前で会社の印鑑証明書を法務局に発行してもらうことができるようになります。
            </dd>
            <dt>７．委任状</dt>
            <dd>
              登記申請を司法書士に依頼する場合は、登記申請の委任状が必要となります。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default OfficerSyorui
